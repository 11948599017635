.serviceContainer:nth-child(1) {
  width: 60%;
}
.serviceContainer:nth-child(2) {
  width: 40%;
}
.serviceContainer:nth-child(3) {
  width: 40%;
}
.serviceContainer:nth-child(4) {
  width: 60%;
}
.serviceContainer:nth-child(5) {
  width: 60%;
}
.serviceContainer:nth-child(6) {
  width: 40%;
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none !important;
}
.ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.683) !important ;
}
.ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.683) !important;
}
.ql-toolbar.ql-snow .ql-formats {
  padding: 8px 15px;
  background-color: #333333;
  border-radius: 10px;
  margin: 0px 5px 5px 0px ;
  color: white;
}
.ql-container {
  margin: 10px 0px;
  background-color: #333333;
  border-radius: 10px;
  color: white;
}
.ql-container {
  font-family: "Rethink Sans", sans-serif !important;
}

@media screen and (max-width: 1100px) {
  .serviceContainer:nth-child(1),
  .serviceContainer:nth-child(2),
  .serviceContainer:nth-child(3),
  .serviceContainer:nth-child(4),
  .serviceContainer:nth-child(5),
  .serviceContainer:nth-child(6),
  .serviceContainer:nth-child(7),
  .serviceContainer:nth-child(8) {
    width: 50%;
  }
}
@media screen and (max-width: 890px) {
  .serviceContainer:nth-child(1),
  .serviceContainer:nth-child(2),
  .serviceContainer:nth-child(3),
  .serviceContainer:nth-child(4),
  .serviceContainer:nth-child(5),
  .serviceContainer:nth-child(6),
  .serviceContainer:nth-child(7),
  .serviceContainer:nth-child(8) {
    width: 100%;
  }
}
